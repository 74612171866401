<template>
  <section id="question">
        <v-sheet class="d-flex justify-space-between border pa-10">
          <v-row>
            <v-col cols="10">
              <div class="d-flex justify-space-between align-center mb-5">
                <div class="poppins fw600 f14 secondary-2--text">Question {{ i+1 }} ({{data.assessment_question.score}} pts):</div>
              </div>
              <div class="question-div roboto f15 secondary-1--text" v-html="data.assessment_question.question ? $dompurifier(data.assessment_question.question) : ''"/>
              <section v-if="data.assessment_question.type_of_question == 'essay'">
                <v-sheet 
                  v-if="data.answer"
                  v-html="data.answer ? $dompurifier(data.answer) : ''" 
                  color="dark-1"
                  class="pa-2 poppins f14 fw400 rounded"/>
                <v-sheet 
                  v-else
                  color="dark-1"
                  style="height: 40px"/>
                <div class="question-div" style="height: 100px" v-else></div>
                <section v-if="data.file" >
                  <Document :file="data.file" />
                  <v-btn
                    :href="data.file" color="primary poppins" class="btn btn-success text-capitalize" download>Download</v-btn>
                </section>
                <!-- <div v-else class="poppins fw600 f13 secondary-4--text font-italic"> Nothing attached. </div> -->
              </section>
              <!-- <section v-if="data.assessment_question.type_of_question == 'identification'">
                <v-sheet 
                  v-if="data.answer"
                  v-html="data.answer" 
                  color="dark-1"
                  class="pa-2 poppins f14 fw400 rounded"/>
              </section> -->
              <section v-else>
                <v-sheet 
                  v-if="data.assessment_question.type_of_question == 'identification'"
                  v-html="data.answer ? $dompurifier(data.answer) : ''" 
                  color="dark-1"
                  class="pa-2 poppins f14 fw400 rounded"/>
                <div class="d-flex my-2 mt-n5" v-if="data.assessment_question.type_of_question !== 'identification'">
                  <div class="d-flex flex-column flex-grow-1">
                    <v-radio-group
                      dense
                      v-model="data.answer"
                    >
                      <Choices
                        v-for="(item, n) in data.assessment_question.assessment_answer.choices"
                        :key="n"
                        :index="n"
                        :item="item"
                      />
                    </v-radio-group>
                  </div>
                </div>
                <section v-if="data.assessment_question.type_of_question !== 'identification' && (data.assessment_question.type_of_question == 'justified_multiple_choice' || data.assessment_question.type_of_question == 'modified_true_or_false')" class="mb-1">
                  <div class="poppins fw600 f12 secondary-4--text font-italic"> {{ justifyText }} </div>
                  <v-textarea 
                    outlined
                    dense
                    readonly
                    auto-grow
                    rows="1"
                    :value="data.support_answer" 
                    class="poppins f14 fw400 rounded my-2"
                    />
                </section>
                <v-sheet class=" pa-2 rounded" color="dark-1" v-if="data.assessment_question.type_of_question !== 'identification'">
                  <div class="poppins fw600 f14 secondary-2--text">Correct Answer</div>
                  <div class="d-flex align-center my-1">
                    <v-radio color="success" class="" />
                    <div class="roboto f14 fw500 secondary-1--text">{{
                      data.assessment_question.assessment_answer.choices[data.assessment_question.assessment_answer.correct_answer.replace('choice_','')][data.assessment_question.assessment_answer.correct_answer]
                    }}</div>
                  </div>
                </v-sheet>
              </section>
              <section v-if="['identification', 'essay', 'justified_multiple_choice'].includes(data.assessment_question.type_of_question)" class="mt-5">
                <div class="poppins fw600 f12 secondary-2--text"> ENTER YOUR FEEDBACK HERE: </div>
                <v-textarea 
                  outlined
                  dense
                  auto-grow
                  rows="5"
                  v-model="comment"
                  class="poppins f14 fw400 rounded my-2"
                  />
              </section>
            </v-col>
            <v-col>
              <div class="justify-start d-flex flex-row align-center">
                <div class="poppins fw600 f14 secondary-2--text mr-1">Score: </div>
                <div class="d-flex flex-row">
                  <label
                    v-if = "data.assessment_question.type_of_question == 'multiple_choice' || data.assessment_question.type_of_question == 'true_or_false' || data.assessment_question.type_of_question == 'identification'"
                    class="mx-auto text-center poppins fw500 f14"
                  >
                    {{ data.score }}
                  </label>
                  <input 
                    v-else
                    type="number"
                    min="0"
                    :max="data.assessment_question.score"
                    class="input-sm mx-auto text-center" 
                    style="width: 80px" 
                    :id="data.id"
                    required
                    v-on:input="data.score > parseFloat(data.assessment_question.score) ? data.score = parseFloat(data.assessment_question.score) : data.score"
                    v-model="data.score"
                  />
                </div>
              </div>
            </v-col>
      </v-row>
    </v-sheet>
    
    <v-btn
      absolute
      fab
      fixed
      bottom
      right
      class="mb-15 mr-5"
      color="primary" 
      elevation="2"
      @click="$emit('save')"
      :loading="loading"
    >
      <v-icon>mdi-content-save </v-icon>
    </v-btn>
    <v-divider class="my-1" />
  </section>
</template>

<script>
import Choices from "./Choices.vue";
import Document from "../../../constants/material/Document.vue";
export default {
  components: {
    Choices,
    Document
},
  props: ["i", "data", "loading"],
  data: () => ({
    choices: ["Answer 1", "Answer 2", "Answer 3", "Answer 4"],
    justifyText: "Justification:"
  }),
  computed: {
    comment: {
      get() {
        return  this.data.question_answer_feedback ? this.data.question_answer_feedback.comment : this.data.question_answer_feedback = null
      },
      set(newValue) {
        this.data.question_answer_feedback ? this.data.question_answer_feedback.comment = newValue : this.data.question_answer_feedback = { comment: newValue}
      }
    }
  }
};
</script>

<style>
  .question-div {
    white-space: normal !important;
    word-wrap: break-word;
  }
</style>